jQuery(function($){
    $(window).scroll(function(e){
        e.preventDefault();
        $('#header').toggleClass('bg-white shadow', $(this).scrollTop() > 50);
        $('#header #header-logo').toggleClass('scrolled', $(this).scrollTop() > 50);
    });
    $('#banner-home').slick({
        dots: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        prevArrow: '<button type="button" class="slick-prev" aria-label="Anterior"><i class="fa-solid fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next" aria-label="Próximo"><i class="fa-solid fa-chevron-right"></i></button>',
    });
    $('.carrousel-quotes').slick({
        dots: false,
        arrows: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        infinite: true,
        speed: 500,
        prevArrow: '<button type="button" class="slick-prev" aria-label="Anterior"><i class="fa-solid fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next" aria-label="Próximo"><i class="fa-solid fa-chevron-right"></i></button>',
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    dots: false,
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    dots: true,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    });
    $('.carrousel-quotes .carrousel-quotes-item').on('click', function() {
        $('.slider').slick('slickPause');
    });
    $('#header-menu>ul.menu>li.menu-item-has-children>a').on('click', function(e){
        e.preventDefault();
        $(this).parent('li.menu-item-has-children').toggleClass('active');
        $(this).next('ul.sub-menu').slideToggle('slow');
    });
    $('#mobile-menu>ul.menu>li.menu-item-has-children>a').on('click', function(e){
        e.preventDefault();
        $(this).parent('li.menu-item-has-children').toggleClass('active');
        $(this).next('ul.sub-menu').slideToggle('slow');
    });
    $('.data-lity').on('click', lity);
});